/* global DEVMODE */

/*
  Global Storage Object
-------------------------------------------------- */
export const isDEVMODE = DEVMODE === 'development';

export const globalStorage = {
  'themeUrl': document.getElementById('siteInfos') ? document.getElementById('siteInfos').dataset.themePath : '/',
  'pageScroll': 0,
  'firstLoad': true,
  'isMobile': false,
  'isTouch': false,
  'isChrome': false,
  'isSafari': false,
  'isOpera': false,
  'isFirefox': false,
  'isIE': false,
  'browserVersion': '',
  'transitionFinished': false,
  'headerMinOffset': 120,
  'headerMin': false,
  'menuOpen': false,
};

export const viewStorage = {
  'current': document.querySelector('[data-router-view]'),
  'hasPrllx': false,
  'viewPrllx': null,
  'hasInview': false,
  'viewInView': null,
  'hasAnchors': false,
  'viewAnchors': null,
  'hasScroll': false,
  'viewScroll': null,
  'hasSticky': false,
  'viewSticky': null
};

export const domStorage = {
  'body': document.body,
  'header': document.getElementById('Header'),
  'app': document.getElementById('App'),
  'footer': document.getElementById('Footer'),
  'loader': document.getElementById('Loader'),
  'loaderMask': document.getElementById('LoaderMask'),
  'outdated': document.getElementById('Outdated')
  // 'openMobileMenu': () => {},
  // 'closeMobileMenu': () => {}
};

export const buildAjaxUrl = (action, params = {}) => {
  let url = `/wp-admin/admin-ajax.php?action=${action}`;
  if (Object.keys(params).length) url += '&'+Object.keys(params).map((key) => {
    return key + '=' + params[key]
  }).join('&');
  return url;
}
