import Highway from '@dogstudio/highway';
import {initParticles} from "../utils";
import Form from "../modules/form";

export default class PageContactRenderer extends Highway.Renderer {

	onEnter() {

		var theme_link = this.wrap.querySelector('#theme_url').value;


		this.DOM = { view: this.wrap.lastElementChild };

		initParticles('particles-js');

		new Form('#ContactForm')

	}
}
