import {Component, h, Fragment, createRef} from "preact";
import AddToCart from "./AddToCart";
import CheckIcon from "../pictos/CheckIcon";

export default class SelectionFinancing extends Component {

  render() {
    const {isonline} = this.props;
    return (
      <div className="form-group inter-intra__check">
        <div className="inter-intra__check__item">
          <label htmlFor="toggle-outil"></label>
          <input onChange={(e) => this.onFinancingChange(e)} name={'financing'} className="layout_selector" type="radio" value="online"
                 checked={isonline}/>
          <div className="box">
            <i className="icon check-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="7.766" height="6.513" viewBox="0 0 7.766 6.513">
                <path id="Tracé_617" data-name="Tracé 617" d="M586.182,136.794l1.961,1.961,4.391-4.391"
                      transform="translate(-585.475 -133.657)" fill="none" stroke="#fff" stroke-miterlimit="10"
                      stroke-width="2" fill-rule="evenodd"></path>
              </svg>
            </i>
            <p>Paiement en ligne</p>
          </div>
        </div>
        <div className="inter-intra__check__item">
          <label htmlFor="toggle-outil"></label>
          <input onChange={(e) => this.onFinancingChange(e)} name={'financing'} className="layout_selector" type="radio" value="external"
                 checked={!isonline}/>
          <div className="box">
            <i className="icon check-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="7.766" height="6.513" viewBox="0 0 7.766 6.513">
                <path id="Tracé_617" data-name="Tracé 617" d="M586.182,136.794l1.961,1.961,4.391-4.391"
                      transform="translate(-585.475 -133.657)" fill="none" stroke="#fff" stroke-miterlimit="10"
                      stroke-width="2" fill-rule="evenodd"></path>
              </svg>
            </i>
            <p>Financement externe</p>
          </div>
        </div>
      </div>
    )
  }

  onFinancingChange(e){
    const target = e.target;
    const checked = target.checked;
    if (checked)
      this.props.onValueChange(target.value === 'online')
  }
}
