import Highway from '@dogstudio/highway';

export default class PageSingleBlogRenderer extends Highway.Renderer {

	onEnter() {

		this.DOM = { view: this.wrap.lastElementChild };

		var mySwiper = new Swiper ('.swiper-block-news', {
			// Optional parameters
			loop: false,
			slidesPerView: "auto",
			spaceBetween: 16
		})


	}

}
