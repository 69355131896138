import {Component, h, Fragment, createRef} from "preact";
import AddToCart from "./AddToCart";
import CheckIcon from "../pictos/CheckIcon";

export default class SelectionInter extends Component {


  constructor(props, context) {
    super(props, context);
    this.state = {
      ...this.props,
      selectedCity: null,
      selectedVariation: null
    }
  }

  render() {
    const {sessions, productid, selectedCity, selectedVariation} = this.state;
    const {isonline} = this.props;
    return (
      <>
        <div className="form-group city-filter">
          <select onChange={(e) => this.onChangeCity(e)} className="city-filter__select selectFilters"
                  data-taxo="certification">
            <option className="defaultOption" value="">Choisissez une ville</option>
            {
              Object.keys(sessions).map((city, i) => <option key={i} value={city}>{city}</option>)
            }
          </select>
        </div>
        {
          selectedCity !== null ?
            <>
              <div className="form-group days-filter">
                <h3>Choisissez votre session</h3>
                <div className="days__list">
                  {
                    sessions[selectedCity].map((date) => {
                      return (
                        <div  className="days__list__item">
                          <label htmlFor="toggle-outil"></label>
                          <input onChange={(e) => e.target.checked && this.onVariationSelection(e.target.value)} className="layout_selector" type="radio" id="toggle-outil" name="layout"
                                 data-toggle=""
                                 value={date.value}
                                 checked={selectedVariation == date.value}
                                 required="required"/>
                          <div className="box">
                            <i className="icon check-icon">
                              <CheckIcon/>
                            </i>
                            {
                              date.libelle ?
                                <p className="subhead"><span className="day">{date.libelle}</span></p>
                                :
                                <p className="subhead"><span className="day">{date.day}</span> <span
                                  className="number">{date.daynum}</span> {date.month}</p>
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              {
                selectedVariation && <AddToCart
                  productid={productid}
                  variationid={selectedVariation}
                  isonline={isonline}
                  text={'Ajouter cette formation *'}
                  subtext={'* Vous pourrez choisir votre financement à l’étape suivante'}
                />
              }
            </>
            :
            null
        }
      </>
    )
  }

  onChangeCity(e) {
    const value = e.target.value;
    this.setState({selectedCity: value ? value : null, selectedVariation:null});
  }

  onVariationSelection(selectedVariation) {
    this.setState({selectedVariation})
  }
}
