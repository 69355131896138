import {Component, h, Fragment, createRef} from "preact";

export default class LoadingIcon extends Component {

  render() {
    return (
      <img width={20} height={20} src="/wp-content/themes/actinuum/src/imgs/loading-anim.gif" alt="Chargement"/>
    )
  }
}
