import {Component, h, Fragment, createRef} from "preact";

export default class Tab extends Component {

  render() {
    const {tab, active} = this.props;

    return (
      <div className={"filters__bar__form__item "}>
        <a className={'tab_thematic_link '+(active ? ' active': '')} data-value={tab.term_id ? tab.term_id : 'all'} onClick={(e) => this.onClick(e)} href={tab.url} data-router-disabled>{tab.title}</a>
      </div>
    )
  }

  onClick(e){
    e.preventDefault();
    e.stopPropagation();
    const {title, url, term_id} = this.props.tab;
    window.history.pushState('', title, url);
    this.props.onClick(term_id)
  }
}
