import Highway from "@dogstudio/highway";
import { initParticles } from "../utils";
import Form from "../modules/form";

export default class PageParcoursDiplomantRenderer extends Highway.Renderer {
  onEnter() {
    this.DOM = { view: this.wrap.lastElementChild };
    initParticles("particles-js");

    const form = new Form("#form_download", { returnsFile: true });

    this.introShapesTl = gsap.timeline({
      paused: true,
      delay: globalStorage.firstLoad ? "2" : "1",
      ease: "power3.inOut",
    });

    this.introShapes();
  }

  introShapes() {
    const shapes = document.querySelectorAll(
      ".page-parcours-diplomant .shape_parcours-diplomant"
    );
    const persona = document.querySelector(
      ".page-parcours-diplomant .persona_parcours-diplomant"
    );

    // 1. Big purple semi ellipse
    this.introShapesTl.fromTo(
      shapes[0],
      {
        yPercent: -20,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      0
    );

    // 2. Small yellow ellipse
    this.introShapesTl.fromTo(
      shapes[1],
      {
        yPercent: -100,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      1.2
    );

    // 3. Yellow + Purple ellipses
    this.introShapesTl.fromTo(
      shapes[2],
      {
        xPercent: 40,
      },
      {
        xPercent: 0,
        duration: 0.5,
      },
      0.2
    );

    // 4. Red ellipse
    this.introShapesTl.fromTo(
      shapes[3],
      {
        yPercent: 50,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      0.6
    );

    // 5. Green ellipse
    this.introShapesTl.fromTo(
      shapes[4],
      {
        yPercent: 50,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      0.8
    );

    // Persona
    this.introShapesTl.fromTo(
      persona,
      {
        yPercent: -50,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      1
    );

    this.introShapesTl.play();
  }

  onLeaveCompleted() {
    this.introShapesTl.kill();
  }
}
