import {Component, h, Fragment, createRef} from "preact";

export default class BlogPost extends Component {

  render() {
    const {post} = this.props;

    return (
      <div className="news__card col-12 col-xs-4 col-l-3">
        <a href={post.link}>
          <p className="subhead">{post.date}</p>
          <div className="title">
            <p className="blog-category green">{ post.category }</p>
            <h3>{post.title}</h3>
          </div>
          <div className="author">
            <div className="author__pp">
              <img src={ post.author.avatar } alt={ post.author.name } />
            </div>
            <p className="author__name">{ post.author.name }</p>
          </div>
        </a>
      </div>
    )
  }
}
