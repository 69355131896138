import Highway from '@dogstudio/highway';
import { h, render } from 'preact';
import FormationsList from "../components/FormationsList";
import Form from "../modules/form";
export default class PageListeFormationsRenderer extends Highway.Renderer {

	onEnter() {

		this.DOM = { view: this.wrap.lastElementChild };
    this.preactContainer = this.DOM.view.querySelector('#formations_list');
    const {filters, search, termid, restorefilters} = this.preactContainer.dataset;

    render(
      <FormationsList ref={FormationsList => { window.FormationList = FormationsList }} termid={termid} filters={filters} search={search} restorefilters={restorefilters} />,
      this.preactContainer
    );


    this.menuLink = document.querySelector('.formation_list_link');
    if (this.menuLink) window.HighwayCore.detach([this.menuLink])

    this.menuLink.addEventListener('click', (e) => {
      e.preventDefault();
      window.FormationList.setSelectedThematic(null)
    })

    new Form('#FormDownloadPdf', {
      returnsFile: true,
      beforeSubmit: () => {
        return document.querySelectorAll('input[name^=gokes]:checked').length !== 0;
      }
    })
    this.bindSelectAll()

    var acc = document.getElementsByClassName("accordion__btn");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function(e) {
        e.preventDefault();
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
	}

  bindSelectAll() {
    const toggle = document.getElementById('toggle-all');
    toggle.addEventListener('change', (e) => {
      for (let cb of document.querySelectorAll('input[name^=gokes]')){
        cb.checked = toggle.checked
      }
    })
  }

	onLeave(){
    window.HighwayCore.attach([this.menuLink])
  }

  onLeaveCompleted(){
    $.magnificPopup.close()

  }
}
