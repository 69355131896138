import {Component, h, Fragment, createRef} from "preact";
import Tab from "./Formations/Tab";
import SelectFilter from "./Formations/SelectFilter";
import Pack from "./Formations/Pack";
import Formation from "./Formations/Formation";

export default class FormationsList extends Component {


  constructor(props, context) {
    super(props, context);
    this.filters = {};
    this.page = 1;
    this.timer = null;
    this.state = {
      formation_format: null,
      filters: null,
      results: [],
      loading: true,
      left: true,
      currentThemathic: this.props.termid ? this.props.termid : null,
      showAdvancedFilters: false,
      hasFiltersSelected: false
    }
  }

  componentDidMount() {
    this.filters = JSON.parse(this.props.restorefilters)
    if  (!this.filters.formation_format) this.filters.formation_format = 'Coaching';
    this.state.formation_format = this.filters.formation_format
    if (Object.keys(this.filters).length) this.setState({showAdvancedFilters:true})
    this.fetchFilters();
  }


  render() {
    const {loading, currentThemathic, filters, showAdvancedFilters, results, left, formation_format } = this.state;
    if (filters === null) return null;
    return (
      <>
        <div className="switch__block">
          <input type="checkbox" className="toggle" id="toggle" onChange={(e) => this.onFormatChange(e)} checked={formation_format === 'Formation'}/>
            <label className="left" htmlFor="toggle">Conseil / Coaching</label>
            <label className="right" htmlFor="toggle">Formation</label>
        </div>
        <div className="search-bar__block">
          <div className="container">
            <div className="search-bar">
              <input type="text" onKeyUp={(e) => this.onSearchFieldChange(e.target.value)} className="form-control" name="term" placeholder="Rechercher une compétence" />
                <button className="btn"><span className="btn__txt">Rechercher</span></button>
            </div>
          </div>
        </div>
        <div className="filters">
          <div className="container">
            <div className="filters__bar">
              <form className="filters__bar__form">
                {
                  filters.tabs.map((tab, i) =>{
                    return <Tab onClick={(term_id) => this.setSelectedThematic(term_id)} key={i} tab={tab} active={tab.term_id == currentThemathic}/>
                  })
                }
                <div className="search-filter">
                  <h3>Selectionnez une thématique</h3>
                  <select onChange={(e) => this.onSelectThematicChange(e.target.value)} className="search-filter__select selectFilters" data-taxo="metier">
                    {
                      filters.tabs.map((item, i) => {
                        return <option value={item.term_id} key={i} selected={currentThemathic == item.term_id}>{item.title}</option>
                      })
                    }
                  </select>
                </div>
              </form>
              <button onClick={(e) => this.toggleAdvancedFilters()} id="more_filters" className="btn purple">
                <span className="btn__txt">{ showAdvancedFilters ? 'Moins de filtres' : 'Plus de filtres'}</span>
              </button>
            </div>
            <div className={"sub-filters__bar "+(showAdvancedFilters ? 'sub-filters__bar--show' : '')}>
              <form id="formSubFilters" className="" data-ajaxurl="" method="post">

                {
                  filters.selects.map((select, i) =>{
                    return <SelectFilter selected={this.filters[select.name]} onChange={(name,value) => this.applyFilter(name,value)} key={i} filter={select}/>
                  })
                }
              </form>
            </div>
          </div>
        </div>
        <div className="liste-formations">
          <div className="container">
            <div className="row-flex">
              {
                results.map((result, i) => {
                  return result.type === 'formations' ? (<Formation key={i} formation={result} showThematic={this.state.currentThemathic === null}/>) : (<Pack key={i} pack={result} showThematic={this.state.currentThemathic === null}/>)
                })
              }
              {
                loading ?
                  <span class='loading-formations'>Chargement des formations</span>
                  :
                  this.state.results.length === 0 ? <span class="no-result">Aucun résultats 😢</span> : null
              }
            </div>
            {
              !loading && left ?
                <div className="center">
                  <a href="" onClick={(e) => this.viewMore(e)} className="link mint center">Charger plus de formations</a>
                </div>
                :
                null

            }
          </div>
        </div>
      </>
    )
  }

  applyFilter(name, value){
    if (!value) delete this.filters[name]
    else this.filters[name] = value;
    this.page=1
    this.setState({results:[]}, () => this.runSearch() )
  }

  setSelectedThematic(currentThemathic){
    this.setState({currentThemathic})
    this.page=1
    this.setState({results:[]}, () => this.runSearch() )
  }


  onSelectThematicChange(value){
    value = value ? value : 'all';
    const link = document.querySelector('.tab_thematic_link[data-value='+value+']')
    link.click()
  }

  onSearchFieldChange(text){
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.page=1
      this.setState({results:[]}, () => this.runSearch() )
    }, 800);
    if (text) this.filters.s = text;
    else delete this.filters.s;
  }

  fetchFilters() {
    fetch(this.props.filters)
      .then(res => res.json())
      .then(filters => {
        this.setState({filters});
        this.runSearch()
      })
  }

  runSearch() {
    this.setState({loading:true, hasFiltersSelected: (Object.keys(this.filters).length === 0 )})
    const base_url = this.props.search;
    let querystring = Object.keys(this.filters).map((key) => {
      return key + '=' + this.filters[key]
    }).join('&');
    if (querystring.length) window.history.replaceState(null, null, '?'+querystring)
    else window.history.replaceState({}, '', location.pathname);

    if (this.state.currentThemathic) querystring += `&thematic=${this.state.currentThemathic}`
    querystring += '&page='+this.page
    const url = base_url + '&' + querystring;
    fetch(url, {
      headers:{
        contentType: "application/json; charset=utf-8",
      }
    })
      .then(res => res.json())
      .then(res => {
        console.log(res);
        this.setState({
          results: [...this.state.results, ...res.results],
          left: res.left,
          loading: false
        });
      })
  }

  toggleAdvancedFilters() {
    this.setState({showAdvancedFilters: !this.state.showAdvancedFilters})
  }

  viewMore(e) {
    e.preventDefault();
    this.page++;
    this.runSearch()
  }

  onFormatChange(e) {
    const formation_format = e.target.checked ? 'Formation' : 'Coaching';
    this.setState({formation_format});
    this.filters['formation_format'] = formation_format
    this.setState({results:[]}, () => this.runSearch() )
  }
}
