import Highway from '@dogstudio/highway';
import {initParticles} from "../utils";

export default class PageDevenirPartenaireRenderer extends Highway.Renderer {

	onEnter() {
		
		var theme_link = this.wrap.querySelector('#theme_url').value;


		this.DOM = { view: this.wrap.lastElementChild };
		initParticles('particles-js');

	}
}
