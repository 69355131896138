/* global gsap ScrollToPlugin */
import Highway from '@dogstudio/highway';


export default class SingleAuthor extends Highway.Renderer {
  onEnter() {

    this.DOM = { view: this.wrap.lastElementChild };

    var swiperArtcles = new Swiper ('.swiper-block-news', {
      // Optional parameters
      loop: false,
      slidesPerView: "auto",
      spaceBetween: 16
    })

    var swiperQuote = new Swiper ('.swiper-quote', {
      // Optional parameters
      loop: false,
      autoplay: true,
      speed: 600,
      slidesPerView: "auto",
      spaceBetween: 24
    })
  }
}
