/* global gsap */
import Highway from '@dogstudio/highway';

export default class DefaultPageTransition extends Highway.Transition {

  in({ from, to, trigger, done }) {
    // Remove Last Page
    if (window.DEVMODE) console.log('Leaving Page : ', from);
    from.parentNode.removeChild(from);

    if (window.cancel_transition === undefined || !window.cancel_transition){
      const Loader = document.querySelector('.Loader');

      // Timeline animation page enter
      const pageIn = gsap.timeline({
        paused: true,
        defaults: {
          duration: 1,
          ease: 'power3.inOut'
        },
        onStart: () => {
          document.body.classList.remove('is--loading');
        },
        onComplete: () => {
          document.body.classList.remove('is--animating');
          done();
        }
      });

      pageIn.fromTo(Loader.querySelector('.inner'), { y: 0 }, { y: '75%', clearProps: 'all' }, 0);
      pageIn.fromTo(Loader, { y: 0 }, { y: '-100%', clearProps: 'all' }, 0);
      pageIn.fromTo(to, { y: 100 }, { y: 0, clearProps: 'all' }, 0);
      pageIn.play();
    } else {
      done()
    }
  }

  out({ from, trigger, done }) {
    if (window.cancel_transition === undefined || !window.cancel_transition){
      const Loader = document.querySelector('.Loader');

      // Timeline animation page leave
      const pageOut = gsap.timeline({
        paused: true,
        defaults: {
          duration: 1,
          ease: 'power3.inOut'
        },
        onStart: () => {
          document.body.classList.add('is--loading');
          document.body.classList.add('is--animating');
        },
        onComplete: () => {
          done();
        }
      });
      pageOut.fromTo(Loader.querySelector('.inner'), { y: '-75%' }, { y: '0%' }, 0);
      pageOut.fromTo(Loader, { y: '100%' }, { y: '0%' }, 0);
      pageOut.to(from, { y: -20, clearProps: 'all' }, 0);
      pageOut.play();
    } else {
      done()
    }
  }
}
