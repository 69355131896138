import {Component, h} from "preact";
import CartIcon from "../pictos/CartIcon";
import {buildAjaxUrl} from "../../_globals";
import LoadingIcon from "../pictos/LoadingIcon";
import CheckIcon from "../pictos/CheckIcon";

export default class AddToCart extends Component {


  constructor(props, context) {
    super(props, context);
    const {text, subtext, color} = props;
    this.state = {
      addedToCart: false,
      loading: true,
      isError: false,
      text,
      subtext,
      color,
    }
  }

  componentDidMount() {
    this.fetchStatus()
  }

  componentDidUpdate(nextProps) {
    const { variationid, productid } = this.props
    if (nextProps.variationid !== variationid || nextProps.productid !== productid) {
      this.fetchStatus()
    }
    return nextProps;
  }

  render() {
    const {addedToCart, loading, text, subtext, isError, color} = this.state;
    if (loading) return this.renderLoading();
    if (isError)  return this.renderError();
    if (addedToCart) return this.renderAddedToCart();

    return (
      <div className="buy__btn">
        <button type={'button'} onClick={() => {this.addToCart()}} className={`btn ${color}`}>
          <i className="btn__icon cart-icon left">
            <CartIcon/>
          </i>
          <span className="btn__txt">{text}</span>
        </button>
        { subtext && <p><small>{subtext}</small></p> }
      </div>
    );
  }

  renderLoading() {
    const {text} = this.state;
    return (
      <div className="buy__btn">
        <button type={'button'} className="btn is--loading" disabled={true}>
          <i className="btn__icon left">
            <LoadingIcon/>
          </i>
          <span className="btn__txt">{text}</span>
        </button>
      </div>
    );
  }

  renderAddedToCart() {
    return (
      <div className="buy__btn">
        <button type={'button'} className="btn green" disabled={true}>
          <i className="btn__icon cart-icon left">
            <CheckIcon/>
          </i>
          <span className="btn__txt">{'Ajouté au panier'}</span>
        </button>
      </div>
    )
  }


  renderError() {
    const {isError} = this.state;
    return (
      <div className="buy__btn">
        <button type={'button'} className="btn orange" disabled={true}>
          <i className="btn__icon cart-icon left">
            <CartIcon/>
          </i>
          <span className="btn__txt">{isError}</span>
        </button>
      </div>
    )
  }

  addToCart(){
    this.setState({loading: true})

    const {productid, variationid, isonline, gomy} = this.props;
    const url = buildAjaxUrl('add_to_cart', {
      productid,
      variationid,
      isonline: isonline ? 1 : 0 ,
      gomy: gomy
    });
    fetch(url)
      .then((res) => res.json())
      .then((res) => {

        if (res.count) {
          const cartCount = document.getElementById('cartCount');
          const cartCountLink = cartCount.parentNode.parentNode;
          console.log(res.count);
          if ( res.count > 0 ) {
            cartCountLink.classList.add('is--filled');
            cartCount.innerHTML = res.count;
          } else {
            cartCountLink.classList.remove('is--filled');
          }
        }

        this.setState({
          addedToCart: res.success,
          isError: res.success ? false : res.message ,
          loading: false
        })
      })
  }

  fetchStatus() {
    this.setState({loading: true})
    const {productid, variationid, isonline} = this.props;
    const url = buildAjaxUrl('check_in_cart', {
      productid,
      variationid,
      isonline: isonline ? '1' : '0',
    });
    fetch(url)
      .then((res) => res.text())
      .then((text) => {
        this.setState({addedToCart: (text == 1), loading: false})
      })
  }

}
