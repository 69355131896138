import {globalStorage} from "../_globals";
import Highway from '@dogstudio/highway';
import { h, render } from 'preact';
import FormationsList from "../components/FormationsList";
import Form from "../modules/form";
export default class PageGokeRenderer extends Highway.Renderer {

	onEnter() {
		this.DOM = { view: this.wrap.lastElementChild };
    new Form('#FormDownloadPdf', {
      returnsFile: true,
    })

    var articlesSwiper = new Swiper ('.swiper-block-news', {
      // Optional parameters
      loop: false,
      slidesPerView: "auto",
      spaceBetween: 16
    })

    var formationsSwiper = new Swiper(".swiper-formations-list", {
      // Optional parameters
      loop: false,
      slidesPerView: 1,
      spaceBetween: 16,
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
    });
    var gomysSwiper = new Swiper ('.swiper-gomys', {
      // Optional parameters
      loop: false,
      autoplay: true,
      speed: 400,
      slidesPerView: "auto",
      centerInsufficientSlides: true,
      spaceBetween: 16
    })

    this.introShapesTl = gsap.timeline({
      paused: true,
      delay: globalStorage.firstLoad ? "2" : "1",
      ease: "power3.inOut",
    });

    this.introShapes();
  }

  introShapes() {
    const shapes = document.querySelectorAll('.single-goke .page__header .shape_single-goke');
    console.log(shapes);

    // 1. Rounded rectangle
    this.introShapesTl.fromTo(shapes[0], { yPercent: 100 }, { yPercent: 0 }, 0.2);
    // 2. Persona
    this.introShapesTl.fromTo(shapes[1], { xPercent: -40, }, { xPercent: 0 }, 0);
    // 3. Top ellipse
    this.introShapesTl.fromTo(shapes[2], { xPercent: -20, yPercent: -40 }, { xPercent: 0, yPercent: 0 }, 0.4 );
    // 4. Small top ellipse
    this.introShapesTl.fromTo(shapes[3], { xPercent: 40 }, { xPercent: 0 }, 0.7 );
    // 5. Small right ellipse
    this.introShapesTl.fromTo(shapes[4], { yPercent: 40 }, { yPercent: 0 }, 0.75);
    // 6. Big right ellipse
    this.introShapesTl.fromTo(shapes[5], { xPercent: 30, yPercent: 40 }, { xPercent: 0, yPercent: 0 }, 0.55);
    // 8. Big semi ellipse
    this.introShapesTl.fromTo(shapes[6], { yPercent: 55 }, { yPercent: 0 }, 0.35);

    this.introShapesTl.play();
  }
  
  onLeaveCompleted() {
    this.introShapesTl.kill();
  }

}
