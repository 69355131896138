export default class Header {

	constructor(el) {

		this.DOM = { el: el };
		this.DOM.body = document.body;
		this.DOM.brand = this.DOM.el.querySelector('.brand');
		this.DOM.toggle = this.DOM.el.querySelector('.Toggle__menu');
		this.DOM.subnavLink = this.DOM.el.querySelector('.gokes_list_menuitem');
		this.DOM.subnav = this.DOM.el.querySelector('.subnav');
		this.DOM.menuContainer = this.DOM.el.querySelector('Nav__container');
		this.menuOpen = false;
		this.subnavOpen = false;

		this.init();

	}

	init() {

		// Toggle
		this.DOM.toggle.addEventListener('click', (e) => {
			e.preventDefault();
			this.menuOpen === true ? this.close() : this.open();
		});


		this.DOM.subnavLink.addEventListener('click', (e) => {
			e.preventDefault();
			this.subnavOpen === true ? this.closeSubnav() : this.openSubnav();
		});

	}

	openSubnav() {

		return new Promise((resolve, reject) => {
			console.log(this.DOM.subnav);
			this.subnavOpen = true;
			this.DOM.subnav.classList.add('showSubnav');
		});

	}

	closeSubnav() {

		return new Promise((resolve, reject) => {
			this.subnavOpen = false;
			this.DOM.subnav.classList.remove('showSubnav');

		});

	}

	open() {

		return new Promise((resolve, reject) => {
			this.menuOpen = true;
			this.DOM.body.classList.add('showMenu');
			this.DOM.body.style.overflow = 'hidden';
		});

	}

	close() {

		return new Promise((resolve, reject) => {

			this.menuOpen = false;
			this.DOM.body.classList.remove('showMenu');
			this.DOM.body.style.overflow = '';

		});

	}

}
