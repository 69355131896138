/* global gsap ScrollToPlugin */
import Highway from '@dogstudio/highway';
import {initParticles} from "../utils";
import { h, render } from 'preact';
import FormationSessionSelection from "../components/FormationSessionSelection";
import { globalStorage } from "../_globals";
import CoachingSessionSelection from "../components/CoachingSessionSelection";

export default class PageSingleFormationRenderer extends Highway.Renderer {
	onEnter() {
    this.DOM = { view: this.wrap.lastElementChild };
		var mySwiper = new Swiper ('.swiper-formations-list', {
			// Optional parameters
			loop: false,
			slidesPerView: 1,
			spaceBetween: 16,
			breakpoints: {
				768: {
					slidesPerView: 2
				},
				992: {
					slidesPerView: 3
				},
				1200: {
					slidesPerView: 4
				}
			}
		});

    var gomysSwiper = new Swiper ('.swiper-gomys', {
      // Optional parameters
      loop: false,
      autoplay: true,
      speed: 400,
      slidesPerView: "auto",
      centerInsufficientSlides: true,
      spaceBetween: 16
    })


		this.DOM.view.querySelectorAll('[data-anchor]').forEach((item) => {

      item.addEventListener('click', (e) => {
        e.preventDefault();
        const targetAnchor = e.currentTarget.getAttribute("href");
        gsap.to(window, {
          duration: 0.6,
          scrollTo: { y: targetAnchor, autoKill: false },
          ease: "power3.out",
        });
      });
    });

    this.preactContainer = document.getElementById('session_select_container');
    const {productid, type} = this.preactContainer.dataset;

    if (type === 'coaching'){
      render(
        <CoachingSessionSelection productid={productid} />,
        this.preactContainer
      );
    } else {
      render(
        <FormationSessionSelection productid={productid} />,
        this.preactContainer
      );
    }

    this.sy = 0;
    window.addEventListener('scroll', () => {
      this.sy = window.pageYOffset || document.documentElement.scrollTop;
      if (this.sy > 300) {
        document.querySelector('.formation__sticky-bar').classList.add('show');
      } else{
        document.querySelector('.formation__sticky-bar').classList.remove('show');
      }
    });

    this.introShapesTl = gsap.timeline({
      paused: true,
      delay: globalStorage.firstLoad ? "2" : "1",
      ease: "power3.inOut",
    });

    this.introShapes();
  }

  introShapes() {
    const shapes = document.querySelectorAll(
      ".page-single-formation .shape_single-formation"
    );

    // 1. Big blue ellipse
    this.introShapesTl.fromTo(
      shapes[0],
      {
        yPercent: -100,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      0
    );

    // 2. Orange ellipse (right)
    this.introShapesTl.fromTo(
      shapes[1],
      {
        xPercent: 30,
        yPercent: 120,
      },
      {
        xPercent: 0,
        yPercent: 0,
        duration: 0.5,
      },
      0.15
    );

    // 3. Yellow ellipse
    this.introShapesTl.fromTo(
      shapes[2],
      {
        xPercent: -30,
      },
      {
        xPercent: 0,
        duration: 0.5,
      },
      0.35
    );

    // 4. Purple ellipse
    this.introShapesTl.fromTo(
      shapes[3],
      {
        xPercent: 20,
      },
      {
        xPercent: 0,
        duration: 0.5,
      },
      0.5
    );

    // 5. Green ellipse with orange
    this.introShapesTl.fromTo(
      shapes[4],
      {
        xPercent: -20,
      },
      {
        xPercent: 0,
        duration: 0.5,
      },
      0.65
    );

    this.introShapesTl.play();
  }

  onLeaveCompleted() {
    this.introShapesTl.kill();
  }
}
