import Highway from "@dogstudio/highway";
import { globalStorage } from "../_globals";

export default class PagePersonaRenderer extends Highway.Renderer {
  onEnter() {
    this.DOM = { view: this.wrap.lastElementChild };

    var mySwiper = new Swiper(".swiper-formations-list", {
      // Optional parameters
      loop: false,
      slidesPerView: 1,
      spaceBetween: 16,
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
    });

    var mySwiper = new Swiper(".swiper-financements-list", {
      // Optional parameters
      loop: false,
      slidesPerView: 1,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-financements-button-next",
        prevEl: ".swiper-financements-button-prev",
      },
      breakpoints: {
        768: {
          spaceBetween: 16,
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
        },
      },
    });

    this.introShapesTl = gsap.timeline({
      paused: true,
      delay: globalStorage.firstLoad ? "2" : "1",
      ease: "power3.inOut",
    });

    this.introShapes();
  }

  introShapes() {
    const shapes = document.querySelectorAll(
      ".page-landing .page__header .shape_landing"
    );
    const persona = document.querySelector(
      ".page-landing .page__header .persona_landing"
    );

    // 1. Purple Rectangle
    this.introShapesTl.fromTo(
      shapes[0],
      {
        yPercent: 40,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      0
    );

    // 2. Middle orange ellipse
    this.introShapesTl.fromTo(
      shapes[1],
      {
        yPercent: 20,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      0.35
    );

    // 3. Small green ellipse
    this.introShapesTl.fromTo(
      shapes[2],
      {
        xPercent: 50,
      },
      {
        xPercent: 0,
        duration: 1,
      },
      0.5
    );

    // 4. Small orange ellipse
    this.introShapesTl.fromTo(
      shapes[3],
      {
        xPercent: 50,
      },
      {
        xPercent: 0,
        duration: 0.7,
      },
      0.65
    );

    // 4. Big green ellipse
    this.introShapesTl.fromTo(
      shapes[4],
      {
        xPercent: 50,
      },
      {
        xPercent: 0,
        duration: 0.7,
      },
      0.7
    );

    // 5. Big purple ellipse
    this.introShapesTl.fromTo(
      shapes[5],
      {
        xPercent: 30,
        yPercent: -40,
      },
      {
        xPercent: 0,
        yPercent: 0,
        duration: 0.3,
      },
      0.2
    );

    // Persona
    this.introShapesTl.fromTo(
      persona,
      {
        xPercent: -30,
        scale: 0.8,
      },
      {
        xPercent: 0,
        scale: 1,
        duration: 0.5,
      },
      0.2
    );

    this.introShapesTl.play();
  }

  onLeaveCompleted() {
    this.introShapesTl.kill();
  }
}
