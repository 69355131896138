import {Component, h, Fragment, createRef} from "preact";

export default class Filter extends Component {

  render() {
    const {filter, selected} = this.props;

    return (
      <div className="filters__bar__form__item">
        <label htmlFor="toggle-tout"></label>
        <input onChange={(e) => this.onChange(e)} className="layout_selector" type="radio" id="toggle-tout" name="layout" data-toggle=""
               value={filter.value} required="required" checked={selected ? 'checked' : null}/>
        <div className="box">
          <p>{filter.display}</p>
        </div>
      </div>
    )
  }

  onChange(e){
    if (e.target.checked) this.props.onFilterChange(this.props.filter.value)
  }
}
