import {Component, h, Fragment, createRef} from "preact";

export default class CartIcon extends Component {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="25.5" height="20.055" viewBox="0 0 25.5 20.055">
        <g id="Panier" transform="translate(0.75 0.305)">
          <path id="Tracé_743" data-name="Tracé 743" d="M190,106h6a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1h-4"
                transform="translate(-173 -101)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.5"/>
          <path id="Tracé_744" data-name="Tracé 744" d="M178,110h-4a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h9"
                transform="translate(-173 -101)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.5"/>
          <path id="Tracé_745" data-name="Tracé 745"
                d="M195.25,110.125l-2.061,9.118a1,1,0,0,1-.97.757H177.781a1,1,0,0,1-.97-.757l-2.019-9.16"
                transform="translate(-173 -101)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.5"/>
          <line id="Ligne_24" className="cart-handle" data-name="Ligne 24" x1="4" y2="9" transform="translate(12)"
                fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.5"/>
          <line id="Ligne_25" data-name="Ligne 25" y2="5" transform="translate(8 11)" fill="none" stroke="#fff"
                stroke-miterlimit="10" stroke-width="1.5"/>
          <line id="Ligne_26" data-name="Ligne 26" y2="5" transform="translate(12 11)" fill="none" stroke="#fff"
                stroke-miterlimit="10" stroke-width="1.5"/>
          <line id="Ligne_27" data-name="Ligne 27" y2="5" transform="translate(16 11)" fill="none" stroke="#fff"
                stroke-miterlimit="10" stroke-width="1.5"/>
        </g>
      </svg>
    )
  }
}
