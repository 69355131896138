import Highway from "@dogstudio/highway";
import gsap from "gsap";
import { globalStorage } from "../_globals";
import { h, render } from "preact";
import BlogList from "../components/BlogList";
export default class PageBlogRenderer extends Highway.Renderer {
  onEnter() {
    this.DOM = { view: this.wrap.lastElementChild };

    this.preactContainer = document.getElementById("clients_list");
    const { fetch } = this.preactContainer.dataset;
    render(<BlogList fetch={fetch} />, this.preactContainer);

    this.introShapesTl = gsap.timeline({
      paused: true,
      delay: globalStorage.firstLoad ? "2" : "1",
      ease: "power3.inOut",
    });

    this.introShapes();
  }

  introShapes() {
    const shapes = document.querySelectorAll(
      ".page-blog .page__header--blog .shape_blog"
    );

    // 1. Small red ellipse (top left)
    this.introShapesTl.from(shapes[0], {
      yPercent: 30,
      xPercent: -50,
      duration: 0.5,
    });

    // 2. Small yellow ellipse (top right)
    this.introShapesTl.from(
      shapes[1],
      {
        yPercent: 20,
        xPercent: 30,
        duration: 0.5,
      },
      0.15
    );

    // 3. Small green ellipse (top/bottom left)
    this.introShapesTl.from(
      shapes[2],
      {
        xPercent: 20,
        yPercent: 45,
        duration: 0.5,
      },
      0.3
    );

    // 4. Small blue ellipse (top/bottom right)
    this.introShapesTl.from(
      shapes[3],
      {
        xPercent: 50,
        yPercent: 35,
        duration: 0.5,
      },
      0.45
    );

    // 5. Small yellow ellipse (left)
    this.introShapesTl.from(
      shapes[4],
      {
        xPercent: -100,
        duration: 0.5,
      },
      0.6
    );

    // 6. Big green ellipse
    this.introShapesTl.from(
      shapes[5],
      {
        xPercent: -40,
        duration: 0.7,
      },
      0.8
    );

    // 7. Big blue ellipse
    this.introShapesTl.from(
      shapes[6],
      {
        xPercent: 60,
        yPercent: 40,
        duration: 0.7,
      },
      1.1
    );

    // 8. Big green ellipse (bottom)
    this.introShapesTl.from(
      shapes[7],
      {
        yPercent: 40,
        duration: 0.7,
      },
      1.4
    );

    this.introShapesTl.play();
  }

  onLeaveCompleted() {
    this.introShapesTl.kill();
  }
}
