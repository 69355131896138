import {Component, h, Fragment, createRef} from "preact";
import AddToCart from "./AddToCart";
import CheckIcon from "../pictos/CheckIcon";
import GomySelection from "./GomySelection";

export default class SelectionPresentiel extends Component {


  constructor(props, context) {
    super(props, context);
    this.state = {
      ...this.props,
      selectedCity: null,
      selectedGomy: null,
    }
  }

  render() {
    const {sessions, productid, selectedCity, selectedGomy} = this.state;
    const {isonline} = this.props;
    return (
      <>
        <div className="form-group city-filter">
          <select onChange={(e) => this.onChangeCity(e)} className="city-filter__select selectFilters"
                  data-taxo="certification">
            <option className="defaultOption" value="">Choisissez une ville</option>
            {
              sessions.map((session, index) => <option key={index} value={index}>{session.city}</option>)
            }
          </select>
        </div>
        {
          selectedCity !== null ?
            <>
              <GomySelection onSelectGomy={(selectedGomy) => {this.setState({selectedGomy})}} gomies={selectedCity.gomies} />
              {
                selectedGomy !== null && <AddToCart
                  gomy={selectedGomy}
                  productid={productid}
                  variationid={selectedCity.value}
                  isonline={isonline}
                  text={'Ajouter cette formation *'}
                  subtext={'* Vous pourrez choisir votre financement à l’étape suivante'}
                />
              }
            </>
            :
            null
        }
      </>
    )
  }

  onChangeCity(e) {
    const index = e.target.value;
    if (index !== "") this.setState({selectedCity: this.state.sessions[index]});
    else this.setState({selectedCity: null});
  }

  onVariationSelection(selectedVariation) {
    this.setState({selectedVariation})
  }
}
