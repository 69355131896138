/* global DEVMODE, gsap */

import FastClick from 'modern-fastclick';

import { newSniff } from './modules/sniffer.js';
import Header from './parts/header.js';
import Footer from './parts/footer.js';
import Routes from './modules/routes.js';
import Form from "./modules/form";
window.DEVMODE = DEVMODE === 'development';
window.Sniff;

class App {

  constructor() {

    // Declarations
    this.sy = 0;
    this.sniffer;
    this.DOM = {};
    this.DOM.Body = document.body;
    this.DOM.App = this.DOM.Body.querySelector('.App');
    this.DOM.Header = this.DOM.Body.querySelector('.Header');
    this.DOM.Footer = this.DOM.Body.querySelector('.Footer');
    this.DOM.Loader = this.DOM.Body.querySelector('.Loader');
    this.DOM.Outdated = this.DOM.Body.querySelector('#Outdated');

    // Signature Wokine
    console.log('%cCreated by Wokine', 'color: #1EA2F1; padding: 5px 0px 1px; border-bottom:2px solid #1EA2F1;');
    //console.log('%chttp://wokine.com', 'color:#6154C7');

    this.init();
    // this.addEvents();

  }

  init() {

    // Sniffer
    window.Sniff = newSniff();

    // OutdatedBrowser
    if (window.Sniff.browser.name === 'ie' && window.Sniff.browser.majorVersion <= 10) {
      console.log('<= ie010');
      this.DOM.Body.classList.remove('is--first');
      this.DOM.Body.classList.remove('is--loading');
      this.DOM.Outdated.classList.add('show');
      return;
    }

    // Chrome Scroll Manual
    this.DOM.Body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    history.scrollRestoration = 'manual';

    // Create Header/Footer/Routes
    this.Header = new Header(this.DOM.Header);
    this.Footer = new Footer(this.DOM.Footer);
    this.Routes = new Routes(this.Header);

    // DOMContentLoaded
    document.addEventListener('DOMContentLoaded', () => {

      // Add Fast Click
      new FastClick(document.body);
      // Reset Scroll
      window.scrollTo(0, 0);
      // Intro
      this.intro();

    }, false);

  }

  addEvents() {
    window.addEventListener('scroll', () => {
      this.sy = window.pageYOffset || document.documentElement.scrollTop;
    });

  }

  intro() {

    const intro = gsap.timeline({
      paused: true,
      defaults: {
        duration: 1
      },
      onStart: () => {
        this.DOM.Body.classList.remove('is--first');
        this.DOM.Body.classList.remove('is--loading');
      },
      onComplete: () => {
        this.DOM.Body.classList.remove('is--animating');
      },
      delay: 0.6
    });

    intro.fromTo(this.DOM.Loader.querySelector('.brand'), { opacity: 0, scale: 1.2 }, { opacity: 1, scale: 1, ease: 'power3.out', clearProps: 'all' }, 0);
    intro.fromTo(this.DOM.Loader.querySelector('.inner'), { y: 0 }, { y: '75%', ease: 'power3.inOut', clearProps: 'all' }, 0.8);
    intro.fromTo(this.DOM.Loader, { y: 0 }, { y: '-100%', ease: 'power3.inOut', clearProps: 'all' }, 0.8);
    intro.play();

    new Form('#NewsletterForm');

  }
}

new App();
