// Highway
import Highway from '@dogstudio/highway';
import DefaultPageRenderer from '../pages/page-renderer.js';
import HomePageRenderer from '../pages/page-home.js';
import PagePersonaRenderer from '../pages/page-persona.js';
import PageEquipeRenderer from '../pages/page-equipe.js';
import PageOutilsPedagogiquesRenderer from '../pages/page-outils-pedagogiques.js';
import PageDevenirPartenaireRenderer from '../pages/page-devenir-partenaire.js';
import PageContactRenderer from '../pages/page-contact.js';
import PageParcoursCertifiantRenderer from '../pages/page-parcours-certifiant.js';
import PageParcoursDiplomantRenderer from '../pages/page-parcours-diplomant.js';
import PageListeFormationsRenderer from '../pages/page-formations.js';
import PageClientsRenderer from '../pages/page-clients.js';
import PageSingleBlogRenderer from '../pages/page-single-blog.js';
import PageSingleFormationRenderer from '../pages/single-formation.js';
import PageCart from '../pages/page-cart.js';
import DefaultPageTransition from '../pages/page-transition.js';

import pagePrllx from './prllx.js';
import pageInView from './inview.js';
import pageScroll from './scroll.js';
// import pageAnchors from './anchors.js';
import PageBlogRenderer from "../pages/page-blog";
import {render, h} from "preact";
import AddToCart from "../components/Formations/AddToCart";
import PageGokeRenderer from "../pages/page-goke";
import SingleAuthor from "../pages/author";

export default class Routes {

  constructor(Header) {

    this.view = {};

    this.header = Header;
    this.navLinks = this.header.DOM.el.querySelectorAll('.Nav__link');

    this.createHighway();
    this.initView(document.body);

    // add class header for items color
    const h = document.querySelector("header");
    const dh = document.querySelector("[data-header]");
    if  (h && dh){
      h.classList.add(dh.dataset.header);
    }

  }

  createHighway() {

    this.Highway = new Highway.Core({
      renderers: {
        pagename: DefaultPageRenderer,
        pagehome: HomePageRenderer,
        pagepersona: PagePersonaRenderer,
        pageequipe: PageEquipeRenderer,
        pagecontact: PageContactRenderer,
        pagedevenirpartenaire: PageDevenirPartenaireRenderer,
        pageparcourscertifiant: PageParcoursCertifiantRenderer,
        pageparcoursdiplomant: PageParcoursDiplomantRenderer,
        pagelisteformations: PageListeFormationsRenderer,
        pagegoke: PageGokeRenderer,
        pagesingleformation: PageSingleFormationRenderer,
        pagesingleblog: PageSingleBlogRenderer,
        pageoutilspedagogiques: PageOutilsPedagogiquesRenderer,
        pagenosclients: PageClientsRenderer,
        pageblog: PageBlogRenderer,
        pageCart: PageCart,
        singleAuthor: SingleAuthor,
      },
      transitions: {
        default: DefaultPageTransition
      }
    });

    window.HighwayCore = this.Highway

    this.Highway.on('NAVIGATE_OUT', ({ from, trigger, location }) => {

      // RemoveEffects
      if (this.view.anchors) this.view.anchors = null;
      if (this.view.inview) this.view.inview = null;

      if (this.view.prllx) {
        this.view.prllx.destroy();
        this.view.prllx = null;
      }

      if (this.view.scroll) {
        this.view.scroll.destroy();
        this.view.scroll = null;
        console.log(this.view.scroll);
      }

    });

    this.Highway.on('NAVIGATE_IN', ({ to, trigger, location }) => {

      // Remove Cache cart
      this.Highway.cache.forEach((value, key) => {
        if (value.slug === 'pageCart') this.Highway.cache.delete(key);
      });

      console.log(this.Highway.cache);

      document.querySelector("header").classList.remove("transparent");
      document.querySelector("header").classList.remove("white");
      document.querySelector("header").classList.add(to.view.dataset.header);

      // Menu active links
      this.navLinks.forEach((link) => {
        link.classList.remove('is-active');
        if (link.href === location.href) {
          link.classList.add('is-active');
        }
      });

      this.initView(to.view);

    });

    this.Highway.on('NAVIGATE_END', ({ to, from, trigger, location }) => {

      if (this.view.scroll) this.view.scroll.update();

      // Analytics
      /* if (typeof gtag !== 'undefined') {
         gtag('config', 'UA-XXXXXXXXX-X', {
           'page_path': location.pathname,
           'page_title': to.page.title,
           'page_location': location.href
         });
      } */

    });

  }

  initView(container) {

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    // Menu Toggle
    if (this.header.menuOpen) {
      const click = new Event('click');
      this.header.DOM.toggle.dispatchEvent(click);
    }

    // if (container.querySelectorAll('[data-anchor]').length > 0) this.view.anchors = new pageAnchors(container);

    // Animations
    if (container.querySelectorAll('[data-scroll-section]').length > 0) this.view.scroll = new pageScroll(container);
    if (container.querySelectorAll('[data-prllxfrom]').length > 0) this.view.prllx = new pagePrllx(container, this.view.scroll);
    if (container.querySelectorAll('[data-inview]').length > 0) this.view.inview = new pageInView(container);

    Routes.initBuyButtons(container)

    const reviews = container.querySelector('#reviews');
    if (reviews && window.Trustpilot){
      let eltp = reviews.querySelector('.trustpilot-widget');
      if (eltp) window.Trustpilot.loadFromElement(eltp);
    }
  }

  static initBuyButtons(container){
    const buy_button_containers = container.querySelectorAll('.buy_button_container');
    if (buy_button_containers){
      for (let bbc of buy_button_containers){
        const dataset =  bbc.dataset;
        render(
          <AddToCart {...dataset} />,
          bbc /* ಠ_ಠ */
        );
      }
    }
  }
}
