import {Component, h, Fragment, createRef} from "preact";
import BlogPost from "./Blog/BlogPost";
import {buildAjaxUrl} from "../_globals";

export default class BlogList extends Component {


  constructor(props, context) {
    super(props, context);
    this.filters = {};
    this.page = 1;
    this.state = {
      filters:[],
      news: [],
      loading: true,
      left: true,
      selectedCategory:null
    }
  }

  componentDidMount() {
    this.fetchFilters();
  }

  fetchFilters(){
    fetch(buildAjaxUrl('blog_filters'))
      .then(res => res.json())
      .then(filters => {
          this.setState({
            filters,
            selectedCategory: this.props.preselect ? parseInt(this.props.preselect) : null
          })
        this.fetchNews();
      });
  }



  render() {
    const {loading, news, left, filters, selectedCategory } = this.state;
    return (
      <>
      <div class="container">
        <div className="filters__block">
          <div className="content">
            <div className="filters">
              <div className="filters__bar">
                <form className="filters__bar__form">
                  { filters.map((term) => {
                    return (
                      <div className="filters__bar__form__item ">
                        <a className={"tab_thematic_link " + (term.id == selectedCategory ? 'active' : '')}
                          href={term.link}
                           onClick={(e) => {
                             e.preventDefault();
                             this.setTerm(term)
                           }} data-router-disabled>{term.name}</a>
                    </div>
                    )
                  })}
                  <div className="search-filter"><h3>Selectionnez une thématique</h3>
                    <select
                    className="search-filter__select selectFilters" data-taxo="metier">
                    { filters.map((term) => {
                      return (
                          <option value={ term.id }>{ term.name }</option>
                        )
                    })}

                  </select></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="news">
        <div className="container">
          <div className="row-flex">
            {
              news.map((post, i) => <BlogPost key={i} post={post}/>)
            }
            {
              loading ?
                <span class='loading-articles'>Chargement des actualités</span>
                :
                news.length === 0 ? <span>Aucune actualité pour le moment.</span> : null
            }
          </div>
          {
            !loading && left ?
              <div className="center">
                <a href="#" onClick={(e) => this.viewMore(e)} className="link purple">Charger plus d'actualités</a>
              </div>
              :
              null
          }
        </div>
      </div>
      </>
    )
  }

  viewMore(e) {
    e.preventDefault();
    this.page++;
    this.fetchNews()
  }

  fetchNews() {
    let url = `${this.props.fetch}&page=${this.page}`;
    if (this.state.selectedCategory){
      url = `${url}&category=${this.state.selectedCategory}`;
    }
    fetch(url)
      .then(res => res.json())
      .then(res => {
        console.log(res);
        const {results, left} = res;
        this.setState({
          news: [...this.state.news, ...results],
          loading:false,
          left
        });
      })
  }

  setTerm(term) {
    this.page = 1;

    window.history.pushState(null, null, term.link)

    this.setState({
      news: [],
      loading: true,
      selectedCategory: term.id
    }, () =>{
      this.fetchNews();
    });
  }
}
