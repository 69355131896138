import {Component, h, Fragment, createRef} from "preact";

export default class SelectFilter extends Component {

  render() {
    const {filter, selected} = this.props;

    return (
      <div className="search-filter">
        <h3>{ filter.title}</h3>
        <select onChange={(e) => this.onChange(e)} className="search-filter__select selectFilters" data-taxo="metier">
          <option className="defaultOption">Toute</option>
          {
            filter.values.map((item, i) => {
              return <option value={item.value} key={i} selected={selected == item.value}>{item.display}</option>
            })
          }
        </select>
      </div>
    )
  }

  onChange(e){
    this.props.onChange(this.props.filter.name, e.target.value)
  }

  onClick(e){
    e.preventDefault();
    const {title, url, term_id} = this.props.tab;
    window.history.pushState('', title, url);
    this.props.onClick(term_id)
  }
}
