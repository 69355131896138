import {Component, h, Fragment, createRef} from "preact";
import routes from "../../modules/routes";

export default class Pack extends Component {

  render() {
    const {pack, showThematic} = this.props;

    return (
      <div className={`formation__card ${pack.color} col-12 col-xs-6 col-sm-4 col-l-3`}>
        <a href={pack.link} onClick={(e) => this.showPopup(e)}>
          <div className="formation__card__title">
            {
              showThematic ?
                <div className="category">
                  <i className="icon">
                    <svg id="Groupe_33" data-name="Groupe 33" xmlns="http://www.w3.org/2000/svg" width="18.069" height="16.09" viewBox="0 0 18.069 16.09">
                      <g id="Groupe_27" data-name="Groupe 27">
                        <path id="Tracé_95" data-name="Tracé 95" d="M272.059,286.665a9.416,9.416,0,0,1-3.8-.748.753.753,0,0,1-.455-.692V272.014a.753.753,0,0,1,1.051-.691,7.946,7.946,0,0,0,3.2.625,22.169,22.169,0,0,0,4.623-.669,23.638,23.638,0,0,1,4.94-.7,9.408,9.408,0,0,1,3.8.748.753.753,0,0,1,.455.691v13.211a.753.753,0,0,1-1.051.692,7.936,7.936,0,0,0-3.2-.625,22.19,22.19,0,0,0-4.623.669A23.638,23.638,0,0,1,272.059,286.665Zm-2.747-1.953a8.116,8.116,0,0,0,2.747.447,22.172,22.172,0,0,0,4.623-.67,23.638,23.638,0,0,1,4.94-.7,9.8,9.8,0,0,1,2.747.371v-11.63a8.115,8.115,0,0,0-2.747-.446,22.172,22.172,0,0,0-4.623.669,23.64,23.64,0,0,1-4.94.7,9.787,9.787,0,0,1-2.747-.371Z" transform="translate(-267.806 -270.575)" fill="#41c7f0"/>
                      </g>
                      <g id="Groupe_29" data-name="Groupe 29" transform="translate(6.961 4.947)">
                        <g id="Groupe_28" data-name="Groupe 28" transform="translate(0 0)">
                          <path id="Tracé_96" data-name="Tracé 96" d="M295.022,294.862v-.55a3.432,3.432,0,0,1-1.762-.866l.7-.851a3.041,3.041,0,0,0,1.062.655v-1.122a2.679,2.679,0,0,1-1.159-.546,1.28,1.28,0,0,1-.369-.986,1.431,1.431,0,0,1,.422-1.061,1.832,1.832,0,0,1,1.107-.5v-.369h.8v.384a3.464,3.464,0,0,1,1.5.625l-.617.889a2.882,2.882,0,0,0-.881-.452V291.2a2.839,2.839,0,0,1,1.2.565,1.287,1.287,0,0,1,.388,1,1.428,1.428,0,0,1-.433,1.069,1.916,1.916,0,0,1-1.156.5v.527Zm0-4.781a.418.418,0,0,0-.324.414.5.5,0,0,0,.324.459Zm1.182,2.741a.415.415,0,0,0-.083-.264.752.752,0,0,0-.3-.2v.926A.469.469,0,0,0,296.2,292.822Z" transform="translate(-293.26 -288.666)" fill="#41c7f0"/>
                        </g>
                      </g>
                      <g id="Groupe_32" data-name="Groupe 32" transform="translate(2.071 7.292)">
                        <g id="Groupe_30" data-name="Groupe 30" transform="translate(0 0)">
                          <line id="Ligne_11" data-name="Ligne 11" x2="2.321" transform="translate(0.753 0.753)" stroke="#41c7f0" stroke-width="1"/>
                          <path id="Tracé_97" data-name="Tracé 97" d="M278.452,298.747h-2.321a.753.753,0,0,1,0-1.506h2.321a.753.753,0,0,1,0,1.506Z" transform="translate(-275.378 -297.241)" fill="#41c7f0"/>
                        </g>
                        <g id="Groupe_31" data-name="Groupe 31" transform="translate(10.101 0)">
                          <line id="Ligne_12" data-name="Ligne 12" x2="2.321" transform="translate(0.753 0.753)" stroke="#41c7f0" stroke-width="1"/>
                          <path id="Tracé_98" data-name="Tracé 98" d="M315.391,298.747H313.07a.753.753,0,0,1,0-1.506h2.321a.753.753,0,0,1,0,1.506Z" transform="translate(-312.317 -297.241)" fill="#41c7f0"/>
                        </g>
                      </g>
                    </svg>
                  </i>
                  <label>{pack.thematic}</label>
                </div>
                :
                null
            }
            <h3>{pack.title}</h3>
          </div>
          <div className="formation__card__content">
            <ul className="formation__advantages-list">
              {
                pack.arguments && pack.arguments.map((argument) => {
                  return (<li>
                    <i className={`icon ${pack.color}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                        <g id="Groupe_2570" data-name="Groupe 2570" transform="translate(-1.255 -2.255)">
                          <line id="Ligne_77" data-name="Ligne 77" y1="6" transform="translate(4.255 2.255)" fill="none" stroke="#fff" stroke-width="2"/>
                          <line id="Ligne_78" data-name="Ligne 78" y1="6" transform="translate(1.255 5.255) rotate(-90)" fill="none" stroke="#fff" stroke-width="2"/>
                        </g>
                      </svg>

                    </i>
                    <p>{argument.text}</p>
                  </li>)
                })
              }
            </ul>
          </div>
          <div className="formation__card__frame">
            <label>{pack.count_text}</label>
          </div>
        </a>
      </div>
    )
  }

  showPopup(e){
    e.preventDefault();
    document.body.style.cursor = 'wait';
    const {pack} = this.props;
    fetch(pack.link, { headers: new Headers({'is-ajax': '1'}) })
      .then(res => res.text())
      .then(html => {
        document.body.style.cursor = 'default';
        const popup = document.getElementById('pack-formation__popup');
        popup.innerHTML = html;
        const currentUrl = location.href
        const currentTitle = document.title
        $.magnificPopup.open({
          items: {
            src: $('#pack-formation__popup')
          },
          callbacks: {
            open: function() {
              window.history.pushState({path:pack.link},pack.title,pack.link);
              window.cancel_transition = true;
              routes.initBuyButtons(popup)
            },
            close: function() {
              window.history.pushState({path:currentUrl},currentTitle,currentUrl);
              window.cancel_transition = false;
            }
          },
          type: 'inline'
        });
      })
  }
}
