import {Component, h, Fragment, createRef} from "preact";
import CheckIcon from "./pictos/CheckIcon";
import CartIcon from "./pictos/CartIcon";
import AddToCart from "./Formations/AddToCart";
import {buildAjaxUrl} from "../_globals";
import SelectionInter from "./Formations/SelectionInter";
import SelectionIntra from "./Formations/SelectionIntra";
import SelectionFinancing from "./Formations/SelectionFinancing";
import SelectionDistanciel from "./Formations/SelectionDistanciel";
import SelectionPresentiel from "./Formations/SelectionPresentiel";

export default class CoachingSessionSelection extends Component {


  constructor(props, context) {
    super(props, context);
    this.state = {
      data: null,
      selectedCity: null,
      inter: false,
      is_online:true,
    }
  }

  componentDidMount() {
    this.fetchSessions()
  }

  render() {
    const {data, distanciel, is_online} = this.state;
    const {productid} = this.props;
    if (data === null) return;

    return (
      <form action="#" className="formation__access__form">
        <div className="form-group inter-intra__check">
          <div className="inter-intra__check__item">
            <label htmlFor="toggle-outil"></label>
            <input onChange={(e) => this.onInterChange(e)} name={'inter'} className="layout_selector" type="radio" value="presentiel"
                   checked={!distanciel}/>
            <div className="box">
              <i className="icon check-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="7.766" height="6.513" viewBox="0 0 7.766 6.513">
                  <path id="Tracé_617" data-name="Tracé 617" d="M586.182,136.794l1.961,1.961,4.391-4.391"
                        transform="translate(-585.475 -133.657)" fill="none" stroke="#fff" stroke-miterlimit="10"
                        stroke-width="2" fill-rule="evenodd"></path>
                </svg>
              </i>
              <p>Prestation presentielle</p>
            </div>
          </div>
          <div className="inter-intra__check__item">
            <label htmlFor="toggle-outil"></label>
            <input onChange={(e) => this.onInterChange(e)} name={'inter'} className="layout_selector" type="radio" value="distanciel"
                   checked={distanciel}/>
            <div className="box">
              <i className="icon check-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="7.766" height="6.513" viewBox="0 0 7.766 6.513">
                  <path id="Tracé_617" data-name="Tracé 617" d="M586.182,136.794l1.961,1.961,4.391-4.391"
                        transform="translate(-585.475 -133.657)" fill="none" stroke="#fff" stroke-miterlimit="10"
                        stroke-width="2" fill-rule="evenodd"></path>
                </svg>
              </i>
              <p>Prestation distancielle</p>
            </div>
          </div>
        </div>
        <br/>
        <br/>
        <SelectionFinancing onValueChange={(is_online) => this.setState({is_online}) } isonline={is_online} />
        {
          distanciel ?
            <SelectionDistanciel isonline={is_online} productid={productid} distanciel={data.distanciel} />
            :
            <SelectionPresentiel isonline={is_online} sessions={data.presentiel} productid={productid}/>
        }
      </form>
    )
  }

  fetchSessions() {
    const url = buildAjaxUrl('fetch_sessions_coaching', {post_id:this.props.productid})
    fetch(url)
      .then(res => res.json())
      .then(data => {
        this.setState({data});
      })
  }

  onInterChange(e){
    const target = e.target;
    const checked = target.checked;
    console.log(e.target, checked);
    if (checked)
      this.setState({
        distanciel: e.target.value === 'distanciel'
      });
  }
}
