import Highway from "@dogstudio/highway";
import gsap from "gsap";
import { initParticles } from "../utils";
import { globalStorage } from "../_globals";

export default class PageParcoursCertifiantRenderer extends Highway.Renderer {
  onEnter() {
    this.DOM = { view: this.wrap.lastElementChild };
    initParticles("particles-js");

    var mySwiper = new Swiper(".swiper-formations-list", {
      // Optional parameters
      loop: false,
      slidesPerView: 1,
      spaceBetween: 16,
      navigation: {
        nextEl: ".swiper-formations-list .swiper-formations-button-next",
        prevEl: ".swiper-formations-list .swiper-formations-button-prev",
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
    });

    this.introShapesTl = gsap.timeline({
      paused: true,
      delay: globalStorage.firstLoad ? "2" : "1",
      ease: "power3.inOut",
    });

    this.introShapes();
  }

  introShapes() {
    const shapes = document.querySelectorAll(
      ".page-parcours-certifiant .page__header .shape_parcours-certifiant"
    );
    const persona = document.querySelector(
      ".page-parcours-certifiant .page__header .persona_parcours-certifiant"
    );
    const shapePath = document.querySelector(
      ".page-parcours-certifiant .page__header .shape_path"
    );

    // 1. Top middle green ellipse
    this.introShapesTl.from(shapes[0], {
      xPercent: 40,
      duration: 0.5,
    });

    // 2. Big green and blue rectangle
    this.introShapesTl.from(
      shapes[1],
      {
        yPercent: 40,
        xPercent: 10,
        duration: 0.5,
      },
      0.25
    );

    // 3. Small blue ellipse (top)
    this.introShapesTl.from(
      shapes[2],
      {
        yPercent: 30,
        xPercent: -20,
        duration: 0.5,
      },
      0.25
    );

    // 4. Small blue ellipse (middle)
    this.introShapesTl.from(
      shapes[3],
      {
        yPercent: 30,
        xPercent: 20,
        duration: 0.5,
      },
      0.35
    );

    // 5. Small blue ellipse (bottom)
    this.introShapesTl.from(
      shapes[4],
      {
        yPercent: 50,
        xPercent: -40,
        duration: 0.5,
      },
      0.45
    );

    // 6. Small orange ellipse
    this.introShapesTl.from(
      shapes[5],
      {
        yPercent: 50,
        duration: 0.5,
      },
      0.55
    );

    // 7. shapePath
    this.introShapesTl.from(
      shapePath,
      {
        yPercent: 50,
        duration: 0.5,
      },
      0.65
    );

    // Persona
    this.introShapesTl.from(
      persona,
      {
        xPercent: -45,
        duration: 0.5,
      },
      0.9
    );

    // 8. Middle blue ellipse (right)
    this.introShapesTl.from(
      shapes[6],
      {
        yPercent: 50,
        duration: 0.5,
      },
      0.75
    );

    // 9. Middle grey ellipse (right)
    this.introShapesTl.from(
      shapes[7],
      {
        yPercent: 50,
        duration: 0.5,
      },
      0.95
    );

    this.introShapesTl.play();
  }

  onLeaveCompleted() {
    this.introShapesTl.kill();
  }
}
