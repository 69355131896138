import {globalStorage} from "../_globals";
import Highway from "@dogstudio/highway";
import gsap from "gsap";
import { initParticles } from "../utils";

export default class PageOutilsPedagogiquesRenderer extends Highway.Renderer {
  onEnter() {
    var theme_link = this.wrap.querySelector("#theme_url").value;

    this.DOM = {
      view: this.wrap.lastElementChild,
    };

    var mySwiper = new Swiper(".swiper-formations-list", {
      // Optional parameters
      loop: false,
      slidesPerView: 1,
      spaceBetween: 16,
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
    });

    initParticles("particles-js");

    this.introShapesTl = gsap.timeline({
      paused: true,
      delay: globalStorage.firstLoad ? "2" : "1",
      ease: "power3.inOut",
    });

    this.introShapes();
  }

  introShapes() {
    const shapes = document.querySelectorAll(
      ".page-outils-pedagogiques .page__header__outils-pedagogiques .shape_outils-pedagogiques"
    );

    // 1. Orange circles
    this.introShapesTl.fromTo(
      shapes[0],
      {
        xPercent: 50,
        yPercent: 40,
      },
      {
        xPercent: 0,
        yPercent: 0,
        duration: 0.7,
      },
      0
    );

    // 2. Orange big circle
    this.introShapesTl.fromTo(
      shapes[1],
      {
        xPercent: -50,
        yPercent: 50,
      },
      {
        xPercent: 0,
        yPercent: 0,
        duration: 0.5,
      },
      0.15
    );

    // 3. Blue big circle
    this.introShapesTl.fromTo(
      shapes[2],
      {
        xPercent: 40,
        yPercent: 40,
      },
      {
        xPercent: 0,
        yPercent: 0,
        duration: 0.5,
      },
      0.35
    );

    // 4. Purple small circle
    this.introShapesTl.fromTo(
      shapes[3],
      {
        xPercent: 75,
        yPercent: -75,
      },
      {
        xPercent: 0,
        yPercent: 0,
        duration: 0.5,
      },
      0.5
    );

    // 5. Green and purple circles
    this.introShapesTl.fromTo(
      shapes[4],
      {
        xPercent: -15,
        yPercent: 75,
      },
      {
        xPercent: 0,
        yPercent: 0,
        duration: 0.5,
      },
      0.65
    );

    this.introShapesTl.play();
  }

  onLeaveCompleted() {
    this.introShapesTl.kill();
  }
}
