export default class Form {

  constructor(formSelector, params={}) {
    this.form = document.querySelector(formSelector);
    this.params = params;
    this.form_url =this.form.dataset.ajaxurl;
    this.formResultText = this.form.querySelector('.form__output p');
    this.submit = this.form.querySelector('[type=submit]');

    this.form.addEventListener('submit', (e) => this.onSubmit(e))
  }


  onSubmit(e) {
    e.preventDefault();

    if  ('beforeSubmit' in this.params){
      if (!this.params.beforeSubmit()){
        return;
      }
    }

    document.body.style.cursor =  'wait';
    this.submit.disabled = true;
    /* Remove previous errors */
    this.formResultText.innerHTML = '';
    this.formResultText.classList.remove('is--error');

    const errors = this.form.querySelectorAll('span.error');
    errors.forEach((err) => {
      err.parentNode.classList.remove('is-error')
      err.remove();
    })

    /* Add values to formData */
    const formData = new FormData();
    const fields = this.form.querySelectorAll('input, select, textarea');
    fields.forEach(field => {
      field.disabled = true;

      if (field.type == 'checkbox'){
        if (field.checked){
          formData.append(field.name, field.value);
        }
      } else {
        field.disabled = true;
        formData.append(field.name, field.value);
      }
    })

    const xhr = new XMLHttpRequest();
    xhr.open('POST', this.form_url, true);
    if (this.params.returnsFile){
      xhr.responseType = 'blob';
    }
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        document.body.style.cursor =  'default';
        this.submit.disabled = false;
        fields.forEach(field => {
          field.disabled = false;
        })
        switch (xhr.status) {
          case 200:
            const responseType = xhr.getResponseHeader("Content-Type").split('/')[0]
            if (responseType === 'application' && this.params.returnsFile){
              this.onSuccessFile(xhr)
            } else {
              this.onSuccess(xhr)
            }
            break;
          case 422:
            if (xhr.responseType === 'blob'){
              xhr.response.text().then(text => {
                this.onInvalid(text);
              })
            } else {
              this.onInvalid(xhr.responseText);
            }
            break;
          default:
            this.onServerError();
        }
      }
    }
    xhr.send(formData);
  }

  onSuccess(xhr) {
    this.formResultText.innerHTML = xhr.responseText;
    this.form.reset()
  }

  onInvalid(text) {
    const json = JSON.parse(text);
    for ( let fieldName of Object.keys(json)){
      const field = this.form.querySelector(`[name=${fieldName}]`)
      if (field){
        const err = document.createElement('span');
        err.innerHTML = json[fieldName].join('. ');
        err.classList.add('error');
        field.parentNode.appendChild(err);
        field.parentNode.classList.add('is-error');
      }
    }
  }

  onServerError() {
    this.formResultText.innerHTML = 'Une erreur est survenue. Merci de réessayer ulterieurement';
    this.formResultText.classList.add('is--error')
  }

  onSuccessFile(xhr) {
    // Try to find out the filename from the content disposition `filename` value
    var filename = "nofilename";
    var disposition = xhr.getResponseHeader('Content-Disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    // The actual download
    var blob = new Blob([xhr.response], { type: 'application/zip' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    this.form.reset()

  }
}
