import {Component, h, Fragment, createRef} from "preact";
import Tab from "./Formations/Tab";
import SelectFilter from "./Formations/SelectFilter";
import Pack from "./Formations/Pack";
import Formation from "./Formations/Formation";
import Filter from "./Clients/Filter";
import Client from "./Clients/Client";

export default class ClientsList extends Component {


  constructor(props, context) {
    super(props, context);
    this.filters = {};
    this.page = 1;
    this.state = {
      selectedFilter: 'all',
      clients: null,
      filters: null,
    }
  }

  componentDidMount() {
    this.fetchData();
  }


  render() {
    const {clients, filters, selectedFilter} = this.state;
    if (clients === null && filters === null) return;
    const filteredClients = clients.filter((client) => (selectedFilter === 'all' || client.terms.includes(selectedFilter)))
    return (
      <>
        <div className="filters">
          <div className="container">
            <div className="filters__bar">
              <form className="filters__bar__form">
                <Filter onFilterChange={(value) => this.onFilterChange('all')} filter={{value: 'all', 'display': 'Tous'}} key={'all'} selected={'all' === selectedFilter}/>
                {
                  filters.map((filter)=> <Filter
                    filter={filter}
                   key={filter.value}
                   selected={filter.value === selectedFilter}
                    onFilterChange={(value) => this.onFilterChange(value)}
                  />)
                }
              </form>
            </div>
          </div>
        </div>
        <div className="clients">
          <div className="container">
            <div className="row-flex clients__list">
              {
                filteredClients.length ?
                  filteredClients.map((client, key) => {
                      return <Client client={client} key={key}/>;
                  })
                  :
                  <i>Aucun client dans cette catégorie !</i>
              }
            </div>
          </div>
        </div>
      </>
    )
  }

  onFilterChange(selectedFilter){
    this.setState({selectedFilter})
  }

  fetchData() {
    fetch(this.props.fetch)
      .then(res => res.json())
      .then(res => {
        const {filters, clients} = res;
        this.setState({filters, clients});
      })
  }
}
