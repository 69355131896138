import {Component, h, Fragment, createRef} from "preact";

export default class CheckIcon extends Component {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="7.766" height="6.513" viewBox="0 0 7.766 6.513">
        <path id="Tracé_617" data-name="Tracé 617" d="M586.182,136.794l1.961,1.961,4.391-4.391" transform="translate(-585.475 -133.657)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2" fill-rule="evenodd"/>
      </svg>
    )
  }
}
