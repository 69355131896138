import Highway from "@dogstudio/highway";
import gsap from "gsap";
import { h, render } from "preact";
import ClientsList from "../components/ClientsList";
import { globalStorage } from "../_globals";
export default class PageClientsRenderer extends Highway.Renderer {
  onEnter() {
    this.DOM = { view: this.wrap.lastElementChild };

    this.preactContainer = document.getElementById("clients_list");
    const { fetch } = this.preactContainer.dataset;
    render(<ClientsList fetch={fetch} />, this.preactContainer);

    this.introShapesTl = gsap.timeline({
      paused: true,
      delay: globalStorage.firstLoad ? "2" : "1",
      ease: "power3.inOut",
    });

    this.introShapes();
  }

  introShapes() {
    const shapes = document.querySelectorAll(
      ".page-nos-clients .page__header .shape_clients"
    );

    // 1. Yellow circle
    this.introShapesTl.fromTo(
      shapes[0],
      {
        xPercent: -45,
        yPercent: 20,
      },
      {
        xPercent: 0,
        yPercent: 0,
        duration: 0.5,
      },
      0
    );

    // 2. White rectangle
    this.introShapesTl.fromTo(
      shapes[1],
      {
        yPercent: 100,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      0.15
    );

    // 3. Little orange rect (top)
    this.introShapesTl.fromTo(
      shapes[2],
      {
        yPercent: -30,
        xPercent: 30,
      },
      {
        yPercent: 0,
        xPercent: 0,
        duration: 0.5,
      },
      0.35
    );

    // 4. Little orange rect (right)
    this.introShapesTl.fromTo(
      shapes[3],
      {
        yPercent: 70,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      0.55
    );

    // 5. Purple rectangles
    this.introShapesTl.fromTo(
      shapes[4],
      {
        xPercent: 30,
        yPercent: 45,
      },
      {
        xPercent: 0,
        yPercent: 0,
        duration: 0.5,
      },
      0.7
    );

    // 6. Big orange rect
    this.introShapesTl.fromTo(
      shapes[5],
      {
        yPercent: 100,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      0.8
    );

    this.introShapesTl.play();
  }

  onLeaveCompleted() {
    this.introShapesTl.kill();
  }
}
