import Highway from '@dogstudio/highway';
export default class PageCart extends Highway.Renderer {

	onEnter() {

		this.DOM = { view: this.wrap.lastElementChild };

		console.log(this);

	}


}
