import {Component, h, Fragment, createRef} from "preact";
import AddToCart from "./AddToCart";
import CheckIcon from "../pictos/CheckIcon";
import GomySelection from "./GomySelection";

export default class SelectionDistanciel extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      ...this.props,
      selectedGomy: null
    }
  }

  render() {
    const {productid, isonline, selectedGomy} = this.state;
    return (
      <>
        <GomySelection onSelectGomy={(selectedGomy) => {this.setState({selectedGomy})}} gomies={this.props.distanciel.gomies} />

        {
          selectedGomy !== null ?
            <AddToCart
              gomy={selectedGomy}
              productid={productid}
              variationid={this.props.distanciel.value}
              isonline={isonline}
              text={'Ajouter cette formation *'}
              subtext={'* Vous pourrez choisir votre financement à l’étape suivante'}
            />
            :null
        }

      </>
    )
  }

}
