import Highway from "@dogstudio/highway";
import { globalStorage } from "../_globals";
import { initParticles } from "../utils";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default class PageEquipeRenderer extends Highway.Renderer {
  onEnter() {
    this.DOM = { view: this.wrap.lastElementChild };
    initParticles("particles-js");

    this.introShapesTl = gsap.timeline({
      paused: true,
      delay: globalStorage.firstLoad ? "2" : "1",
      // delay: 2,
      ease: "power3.inOut",
    });

    // Animation on scroll 
    this.parallaxScrollTrigger;
    this.prllxShape01,
    this.prllxShape02,
    this.prllxShape03,
    this.prllxShape04;
  

    this.introShapes();
    this.scrollShapes();
  }

  introShapes() {
    const shapes = document.querySelectorAll(".shape_equipe");
    const personas = document.querySelectorAll(".persona_equipe");

    // 1. Light blue rectangle
    this.introShapesTl.fromTo(
      shapes[0],
      {
        yPercent: 40,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      0
    );

    // 2. Big white ellipse
    this.introShapesTl.fromTo(
      shapes[1],
      {
        scale: 1.4,
      },
      {
        scale: 1,
        duration: 0.5,
      },
      0.2
    );

    // 3.	Purple rectangle
    this.introShapesTl.fromTo(
      shapes[2],
      {
        yPercent: 40,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      0.5
    );

    // 4. Middle orange ellipse
    this.introShapesTl.fromTo(
      shapes[3],
      {
        yPercent: 30,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      0.7
    );

    // 5. Small green ellipse
    this.introShapesTl.fromTo(
      shapes[4],
      {
        xPercent: -40,
      },
      {
        xPercent: 0,
        duration: 0.5,
      },
      0.8
    );

    // 6. Small purple ellipse
    this.introShapesTl.fromTo(
      shapes[5],
      {
        yPercent: -70,
      },
      {
        yPercent: 0,
        duration: 0.6,
      },
      1
    );

    // 7. Super small orange ellipse
    this.introShapesTl.fromTo(
      shapes[6],
      {
        yPercent: -100,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      1.2
    );

    // Blue Persona
    this.introShapesTl.fromTo(
      personas[0],
      {
        xPercent: -30,
      },
      {
        xPercent: 0,
        duration: 0.5,
      },
      0.15
    );
    // Yellow Persona
    this.introShapesTl.fromTo(
      personas[1],
      {
        yPercent: -30,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      0.2
    );
    // Orange Persona
    this.introShapesTl.fromTo(
      personas[2],
      {
        xPercent: 50,
      },
      {
        xPercent: 0,
        duration: 0.5,
      },
      0.25
    );

    this.introShapesTl.play();
  }

  scrollShapes() {
    const historyContainer = document.querySelector(".actinuum__history");
    const datesContent = historyContainer.querySelectorAll(".actinuum__history__date");
    const shapes = historyContainer.querySelectorAll(".sidebar .shape");

    this.parallaxScrollTrigger = ScrollTrigger.create({
      trigger: "#content",
      start: "top 100px",
      end: (self) => "+=" + (document.querySelector("#content").offsetHeight - self.pin.offsetHeight),
      pin: "#sidebar",
      onRefresh: (self) => (self.pin.parentNode.style.float = "right"),
      pinSpacing: false,
    });

    // 1. Big blue ellipse
    this.prllxShape01 = gsap.timeline({ 
      scrollTrigger: {
        trigger: datesContent[0],
        start: "top 80%",
        end: "bottom 80%",
        // markers: true,
        scrub: true,
      }
    });
    this.prllxShape01.from(shapes[0], {
      opacity: 0,
      transformOrigin: "50% 50%",
      scale: 0.3,
    })

    // 2. Small green ellipse
    this.prllxShape02 = gsap.timeline({
      scrollTrigger: {
        trigger: datesContent[1],
        start: "top 50%",
        end: "bottom 80%",
        // markers: true,
        scrub: true,
      },
    })
    this.prllxShape02.from(shapes[1], {
      opacity: 0,
      transformOrigin: "50% 50%",
      scale: 0.3,
    }, 0);

    // 3. Orange ellipse + Purple ellipse
    this.prllxShape03 = gsap.timeline({
      scrollTrigger: {
        trigger: datesContent[2],
        start: "top 50%",
        end: "bottom 50%",
        markers: false,
        scrub: true,
      },
    })
    this.prllxShape03.from(shapes[2], {
      opacity: 0,
      xPercent: -100
    }, 0);
    this.prllxShape03.from(shapes[3], {
      opacity: 0,
      xPercent: 100,
      delay: 0.15,
    }, 0);

    // 4. All other shapes
    // 4.1. Red ellipse (right)
    this.prllxShape04 = gsap.timeline({
      scrollTrigger: {
        trigger: datesContent[3],
        start: "top 70%",
        end: "bottom 80%",
        // markers: true,
        scrub: true,
      }
    })
    this.prllxShape04.from(shapes[4], {
      opacity: 0,
      xPercent: 60,
    });
    // 4.2. Red ellipse (left)
    this.prllxShape04.from(shapes[5], {
      opacity: 0,
      xPercent: -40,
    });

    // 4.3. Small red ellipse (top)
    this.prllxShape04.from(shapes[6], {
      opacity: 0,
      yPercent: -60,
    });
    // 4.4. Green ellipse
    this.prllxShape04.from(shapes[7], {
      opacity: 0,
      transformOrigin: "50% 50%",
      scale: 0.7,
    });
    // 4.5. Orange ellipse
    this.prllxShape04.from(shapes[8], {
      opacity: 0,
      transformOrigin: "50% 50%",
      scale: 0.7,
    });
  }

  onEnterCompleted() {
    this.parallaxScrollTrigger.refresh();
  }

  onLeaveCompleted() {
    this.introShapesTl.kill();

    this.prllxShape01.kill();
    this.prllxShape02.kill();
    this.prllxShape03.kill();
    this.prllxShape04.kill();
    this.prllxShape01.scrollTrigger.kill();
    this.prllxShape02.scrollTrigger.kill();
    this.prllxShape03.scrollTrigger.kill();
    this.prllxShape04.scrollTrigger.kill();
  }
}
