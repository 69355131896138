import Highway from '@dogstudio/highway';
import {initParticles} from "../utils";
import {
  getPosition
} from '../utils.js';
import {isDEVMODE, globalStorage, viewStorage, domStorage} from '../_globals';
import {pageSticky} from '../modules/sticky';

export default class HomePageRenderer extends Highway.Renderer {

  onEnter() {
    this.DOM = {view: this.wrap.lastElementChild};

    const nextSessionsTitle = this.DOM.view.querySelector('.next-sessions__block .title');
    this.DOM.nextSessionsTitle = nextSessionsTitle;
    this.DOM.newsBlock = this.DOM.view.querySelector('.news__block');

    const ShowMores = {
      'ShowMoreGokes' : '.thematiques__block .thematiques__list__item',
      'ShowMoreFormations' : '.products__list .formation__card',
    }

    Object.keys(ShowMores).forEach((key) => {
      const show_more = document.getElementById(key);
      if (show_more){
        show_more.addEventListener('click', (e) => {
          e.preventDefault();
          const items = document.querySelectorAll(ShowMores[key])
          for (let item of items){
            item.classList.remove('hide');
          }
          show_more.classList.add('hide');
        })
      }
    });

    // this.nextSessionsTitleFixed = this.nextSessionsTitleFixed.bind(this);
    // this.addEvents();
    // gsap.ticker.add(this.nextSessionsTitleFixed);

    var mySwiper = new Swiper('.swiper-block-news', {
      // Optional parameters
      loop: false,
      slidesPerView: "auto",
      spaceBetween: 10,
    });

    initParticles('particles-js');


    window.addEventListener('scroll', function (e) {
      var offset = document.scrollingElement.scrollTop;
      if (offset > 10) {
        document.querySelector('header').classList.remove('transparent');
      } else {
        document.querySelector('header').classList.add('transparent');
      }
    });

    this.introShapesTl = gsap.timeline({
      paused: true,
      delay: globalStorage.firstLoad ? "2" : "1",
      ease: "power3.inOut",
    });

    this.introShapes();
  }

  introShapes() {
    const shapes = document.querySelectorAll(".page__header__home .shape_home");

    // 1. Orange ellipse
    // this.introShapesTl.fromTo(
    //   shapes[0],
    //   {
    //     yPercent: -70
    //   },
    //   {
    //     yPercent: 0,
    //     duration: 0.5
    //   },
    //   0
    // );

    // 2. Dark purple ellipse
    this.introShapesTl.fromTo(
      shapes[0],
      {
        xPercent: -40,
      },
      {
        xPercent: 0,
        duration: 0.5,
      },
      0.2
    );

    // 3. Yellow ellipse
    this.introShapesTl.fromTo(
      shapes[2],
      {
        yPercent: -150,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      0.4
    );

    // 4. Blue & Green ellipses
    this.introShapesTl.fromTo(
      shapes[3],
      {
        yPercent: 50,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      0.6
    );

    // 5. Tiny yellow ellipse
    this.introShapesTl.fromTo(
      shapes[4],
      {
        yPercent: -50,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      0.7
    );

    // 6. Small purple ellipse
    this.introShapesTl.fromTo(
      shapes[5],
      {
        xPercent: 60,
      },
      {
        xPercent: 0,
        duration: 0.5,
      },
      0.85
    );

    // 7. Big Purple & Green ellipses
    this.introShapesTl.fromTo(
      shapes[6],
      {
        yPercent: 60,
      },
      {
        yPercent: 0,
        duration: 0.5,
      },
      0.85
    );

    // 8. Green ellipse (right)
    this.introShapesTl.fromTo(
      shapes[7],
      {
        xPercent: 80,
      },
      {
        xPercent: 0,
        duration: 0.5,
      },
      0.75
    );

    this.introShapesTl.play();
  }

  addEvents() {
    this.sy = 0;
    window.addEventListener('scroll', () => {
      this.sy = window.pageYOffset || document.documentElement.scrollTop;
    });
  }

  nextSessionsTitleFixed() {
    if (window.innerWidth < 992) return;
    const end = Math.round(getPosition(this.DOM.newsBlock).y);
    const isFixed = end >= window.innerHeight;


    console.log("end :" + end);
    console.log("window.innerHeight :" + window.innerHeight);
    if (isFixed) {
      console.log(this.sy);
      gsap.set(this.DOM.nextSessionsTitle, {
        y: this.sy,
        ease: Power0,
      });
    }
  }

  onLeaveCompleted() {
    window.addEventListener('scroll', function (e) {
      var offset = document.scrollingElement.scrollTop;
      if (offset > 10) {
        document.querySelector('header').classList.remove('transparent');
      } else {
        document.querySelector('header').classList.remove('transparent');
      }
    });
    gsap.ticker.remove(this.nextSessionsTitleFixed);

    this.introShapesTl.kill();
  }
}
