import {Component, h, Fragment, createRef} from "preact";

export default class Client extends Component {

  render() {
    const {client} = this.props;
    /*
    * If client has quote, SHOW popup, if not link to external URL
    * */
    return client.quote.quote ?
      (
        <a href={'#'} onClick={(e) => this.showPopup(e)} className="clients__list__item col-6 col-xs-4 col-sm-3">
          <img src={client.logo} alt={client.title} />
        </a>
      )
      :
      (
        <a href={client.link} target={'_blank'} className="clients__list__item col-6 col-xs-4 col-sm-3">
          <img src={client.logo} alt={client.title} />
        </a>
      );
  }

  showPopup(e){
    e.preventDefault();
    const {client} = this.props;

    const popup = document.getElementById('client__popup')
    const popupImg = popup.querySelector('#PopupImage');
    const popupDesc = popup.querySelector('#PopupDesc');
    const popupCitation = popup.querySelector('#PopupCitation');

    popupImg.src = client.logo;
    popupImg.alt = client.title;
    popupCitation.style.display = client.quote ? 'block' : 'none';
    if (client.quote){
      popupDesc.innerHTML = client.quote.quote;
      popupCitation.querySelector('.author__pp img').src = client.quote.photo;
      popupCitation.querySelector('.author__name').innerHTML = client.quote.fullname;
      popupCitation.querySelector('.subhead.author__title').innerHTML = client.quote.job;
    }

    $.magnificPopup.open({
      items: {
        src: $(popup)
      },
      type: 'inline'
    });
  }
}
