import {Component, h, Fragment, createRef} from "preact";
import AddToCart from "./AddToCart";
import CheckIcon from "../pictos/CheckIcon";
import {buildAjaxUrl} from "../../_globals";

export default class GomySelection extends Component {


  constructor(props, context) {
    super(props, context);
    this.state = {
      ...this.props,
    }
  }
  render() {
    return (
      <div className="gomys__choice" >
        <h2>Sélectionnez votre gomy</h2>
        <div className="gomy__list" >
          {this.props.gomies.map((gomy) => {
            return (
              <label htmlFor={'gomy'+gomy.id} className="gomy__list__item">
                <input onChange={(e) => this.onChange(e, gomy.id)}  id={`gomy${gomy.id}`} type="radio" name={"gomy"}/>
                <div className="gomy__item__box">
                  <i className="icon check-icon"><svg xmlns="http://www.w3.org/2000/svg" width="7.766" height="6.513" viewBox="0 0 7.766 6.513"><path id="Tracé_617" data-name="Tracé 617" d="M586.182,136.794l1.961,1.961,4.391-4.391" transform="translate(-585.475 -133.657)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2" fill-rule="evenodd"></path></svg></i>
                  <div class="row-flex">
                    <div class="col-3">
                      <div class="gomys__pp">
                        <img src={gomy.avatar} alt={""}/>
                      </div>
                    </div>
                    <div class="col-8">
                      <h3>{ gomy.name }</h3>
                      <p className="gomy__job">{ gomy.job }</p>
                      {
                        gomy.city ? <p className="gomy__city">@ { gomy.city }</p> : null
                      }
                    </div>
                  </div>

                </div>

              </label>
            )
          })}
          <label htmlFor={'gomyNone'} className="gomy__list__item gomy__list__item__none">
            <input onChange={(e) => this.onChange(e, 0)} id={`gomyNone`} type="radio" name={"gomy"}/>
            <div className="gomy__item__box">
              <i className="icon check-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="7.766" height="6.513" viewBox="0 0 7.766 6.513">
                  <path id="Tracé_617" data-name="Tracé 617" d="M586.182,136.794l1.961,1.961,4.391-4.391"
                        transform="translate(-585.475 -133.657)" fill="none" stroke="#fff" stroke-miterlimit="10"
                        stroke-width="2" fill-rule="evenodd"></path>
                </svg>
              </i>
              <div class="row-flex">
                <div class="col-3">
                  <div class="gomys__pp">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21.453" height="21.523" viewBox="0 0 21.453 21.523">
                      <g id="Groupe_3755" data-name="Groupe 3755" transform="translate(9309.655 16022.797)">
                      <path id="Tracé_2105" data-name="Tracé 2105" d="M9,4.658a9.514,9.514,0,0,1,7.107,17.65m0-5.271v5.923H22.03" transform="translate(-9311.554 -16025.482)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                      <line id="Ligne_84" data-name="Ligne 84" y2="0.012" transform="translate(-9306.546 -16017.733)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                      <line id="Ligne_85" data-name="Ligne 85" y2="0.012" transform="translate(-9308.405 -16013.185)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                      <line id="Ligne_86" data-name="Ligne 86" y2="0.012" transform="translate(-9307.73 -16008.328)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                      <line id="Ligne_87" data-name="Ligne 87" y2="0.012" transform="translate(-9304.733 -16004.454)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                      <line id="Ligne_88" data-name="Ligne 88" y2="0.012" transform="translate(-9300.185 -16002.595)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                      </g>
                    </svg>
                  </div>
                </div>
                <div class="col-8">
                  <h3>Je ne souhaite pas choisir <br/>mon intervenant maintenant</h3>
                </div>
              </div>
            </div>

          </label>
        </div>
      </div>
    )
  }

  onChange(e, gomyId){
    if (e.target.checked){
      this.props.onSelectGomy(gomyId);
    }
  }

}
