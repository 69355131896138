/* global gsap */
import LocomotiveScroll from 'locomotive-scroll';

export default class pageScroll {

  constructor(container) {

    console.log('New pageScroll');

    this.DOM = {
      view: container === document.body ? document.querySelector('.App > *') : container
    };

    this.scroll = new LocomotiveScroll({
      el: this.DOM.view,
      smooth: true
    });

    return this.scroll;

  }

}
